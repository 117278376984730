var component = require('../../../lib/js/component.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

var uniqueIdCounter = 0; // Counter to generate unique IDs

function Lightbox() {

    /**
     * Init for the Card module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {

        var _this = this,
            $element = $(element),
            $previousElement = $element.prev(),
            $youtubeVideo = $element.find('.Lightbox-video'),
            $videoTour = $element.find('.Lightbox-tour');

        _this.player = {}; 
        _this.videoIsReady = false;

        // Generate a unique ID for each video instance
        var uniqueVideoId = 'playerLightbox_' + uniqueIdCounter++;
        $youtubeVideo.attr('id', uniqueVideoId); // Set unique ID to the Lightbox-video div

        // If cinemagraph, add lightbox functionality to the video tag
        if ($previousElement.hasClass('u-responsiveVideo')) {
            $cinemagraph = $($previousElement[0].previousSibling);
            $cinemagraph.attr('data-toggle', 'modal');
            $cinemagraph.attr('data-target', $previousElement.data('target'));
            $cinemagraph.addClass('has-lightbox');

            if ($previousElement.hasClass('has-lightbox-tablet')) {
                $cinemagraph.addClass('has-lightbox-tablet');
            }
        }

        var $image = $element.find('img');

        // Start YouTube API only once
        if(!window.youtubeVideosStack){
            window.youtubeVideosStack = [];
        }
        if (window.youtubeVideosStack.length === 0) {
            startYoutubeAPI();
        }

        function onPlayerReady(event) {
            _this.videoIsReady = true;
        }

        function onPlayerStateChange(event) {
            var iframe = event.target.getIframe();
            var $wrapperElement = $(iframe).closest(".Lightbox-video-wrapper");

            if (event.data === YT.PlayerState.PLAYING) {
                $wrapperElement.trigger("click");
            }

            if (event.data === YT.PlayerState.ENDED) {
                AdobeLaunch.pushVideoStatusChangedEvent($wrapperElement, AdobeLaunch.VIDEO_STATUS_CHANGED_EVENT_NAME);
            }
        }

        $element.on('show.bs.modal', function () {
            if ($image && $image.length > 0) {
                setTimeout(function () {
                    if (!$image.hasClass('is-loaded')) {
                        $image.trigger("RESPONSIVE_LAZYLOAD");
                        $image.cloudinary_update();
                        $.cloudinary.responsive();
                    }
                }, 500);
            }

            if ($youtubeVideo.length > 0) {
                $youtubeVideo.hide(); // Hide the video initially
                var videoId = $youtubeVideo.data('youtube-id');

                setTimeout(function () {
                    if (_this.videoIsReady) {
                        _this.cueVideo(videoId);
                        $youtubeVideo.show();
                    }
                }, 200); // Small delay to avoid flicker
            } else if ($videoTour.length > 0) {
                var videoTourSrc = $videoTour.attr('src');
                if (!videoTourSrc) {
                    setTimeout(function () {
                        $videoTour.attr('src', $videoTour.data('tour'));
                    }, 500);
                }
            }
        });

        $element.on('hide.bs.modal', function () {
            if (_this.videoIsReady) {
                _this.player.stopVideo();
            }

            if ($videoTour.length > 0) {
                $videoTour.attr('src', '');
            }

            $youtubeVideo.hide();
        });

        // Prevent duplicate players by checking the unique ID in youtubeVideosStack
        var existingPlayer = youtubeVideosStack.find(function (item) {
            return item.id === uniqueVideoId;
        });
        if (!existingPlayer) {
            // Add player configuration to the stack
            youtubeVideosStack.push({
                context: _this,
                player: 'player',
                id: uniqueVideoId,
                options: {
                    height: '100%',
                    width: '100%',
                    videoId: '',
                    playerVars: {
                        'autoplay': 0,
                        'rel': 0,
                        'showinfo': 0,
                        'enablejsapi': 1
                    },
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange
                    }
                }
            });
        }

        _this.cueVideo = function (videoId) {
            if (_this.videoIsReady) {
                _this.player.cueVideoById(videoId); // Load video without autoplay
            }
        };
    };
}

module.exports = component(Lightbox);
