var component = require('../../../lib/js/component.js');
var productMapControllerv2 = require('../map/map-product-controller-v2.js');
var utils = require('../../../lib/js/utils.js');
var mapPinHelper = require('../map/map-pin-helper.js');

var ProductMapV2 = function () {
    var _this = this;

    var viewModel = function () {
        var self = this;
        self.viewObservable = {};
    };

    var brandsInLegend = {};
    var _lotMapData = {};

    var viewLookupTable = {
        "community": $(".ProductMapCommunitySidebarV2"),
        "qmi": $(".ProductMapTotalQMISidebarV2")
    };

    var _originalOrder = {};

    var _selectedCommunityID = null;

    this.initialize = function (el, options) {
        this.$body = $('body');
        //store api url if it exists
        if (this.$body.data('api-url') && this.$body.data('api-url').length) {
            this.apiUrl = this.$body.data('api-url');
        } else {
            this.apiUrl = window.location.origin;
        }

        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;

        // Fix issue of community opening in experience modal
        $('.experience-modal-button').on('click', function(e) {
            e.preventDefault();
            $('#modal-experience').modal('show');
        });

        if (!this.hasExperienceMessage) {
            var $expButton = this.$element.find('.experience-modal-button');
            $expButton.attr("href", $expButton.data('href'));
            $expButton.removeAttr('data-toggle');
        }

        var $mapRef = null;
        var productMapEl = $(document).find('.ProductMapV2');
        var state = productMapEl.attr("data-state");
        var region = productMapEl.attr("data-region");
        var city = productMapEl.attr("data-city");
        var brand = $('body').data('brand');
        _lotMapData.$lotMapContainer = $(".lot-map-container");


        this.productLocation = {
            "brand": brand,
            "flag": "",
            "pageNumber": 0,
            "pageSize": 10,
            "productType": "community",
            "qmi": false,
            "region": region,
            "state": state,
            "cityNames": city,
            "data": "",
            "searchForExact": false
        }

        if (city) {
            this.productLocation.flag = "any";
            this.productLocation.data = city;
        } else if (region) {
            this.productLocation.flag = "region";
            this.productLocation.data = region;
        } else {
            this.productLocation.flag = "state";
            this.productLocation.data = state;
        }

        this.$thisMap = this.$element.find(".Map");
        $mapRef = this.$thisMap;

        if (this.$thisMap && this.$thisMap.length > 0) {
            this.$thisMap.attach(productMapControllerv2, Pulte);
        }

        this.$thisMap.on('pinClicked', this.mapPinSelection.bind(this));
        this.$thisMap.on('pmap-loadMapLegend', this.loadMapLegend.bind(this));
           this.$thisMap.on("pmap-pinsLoaded", function() {
            setTimeout(function(){
                highlightFirstCard();
            },2500)
        });

        $('.ProductMapV2-panel').find('.v-card').each(function (_, card) {            
            $(card).on('click', _this.triggerPinSelection.bind(this, $mapRef));
        });

        $('.ProductMapV2-panel-mobile').find('.v-card').each(function (_, card) {            
            $(card).on('click', _this.triggerPinSelection.bind(this, $mapRef));
        });

        var deeplink = window.location.hash.substring(1).toLowerCase(); 
        var communityDeeplinkData = $('.ProductSummaryGalleryv2').data();
        var qmiDeeplinkData = $('.ProductSummaryQMIGalleryv2').data();

        var isCommunityDeeplinkMatch = communityDeeplinkData && communityDeeplinkData.deeplink && deeplink === communityDeeplinkData.deeplink.toLowerCase();
        var isQmiDeeplinkMatch = qmiDeeplinkData && qmiDeeplinkData.deeplink && deeplink === qmiDeeplinkData.deeplink.toLowerCase();

        // Invoke handleView only if deeplink is absent or does not match community and QMI deeplinks
        if (!isCommunityDeeplinkMatch && !isQmiDeeplinkMatch) {
            this.handleView(null, "community");
        }
        
        $(window).on("resize", utils.debounce(reportWindowSize, 250, false));

        this.$element.on("pf-view-change", this.handleView.bind(this));
        this.$element.on("pfv2-filter-change", this.handleFilter.bind(this));

        var newModel = new viewModel();
        this.koModel = newModel;
        ko.applyBindings(newModel, el);

        $("[data-click='show-single-qmis']").on("click", this.showSingleQMIs.bind(this));
        $("[data-click='backtototal']").on("click", this.backToTotalQMIs.bind(this));
    }

    this.triggerPinSelection = function($mapRef, e) {
        var eventTarget = e.target;
        var communityID = $(this).attr('data-community-id');

        if (_this.isCardSelectionEvent(eventTarget, this)) {
            $mapRef.trigger('checkAndExpandCluster', communityID);
        }  
    }

    this.isCardSelectionEvent = function(eventTarget, card) {
        var modalTriggers = $(card).find('[data-toggle=modal]').toArray();
        var doNotTriggerTagNames = ['a', 'button', 'path'];

        return eventTarget == card ||
              (!doNotTriggerTagNames.includes(eventTarget.tagName.toLowerCase()) &&
                !doNotTriggerTagNames.includes(eventTarget.parentElement.tagName.toLowerCase()) &&                
                !eventTarget.parentElement.classList.contains('Carousel-slide') &&
                !eventTarget.classList.contains('Carousel-slide') &&
                !modalTriggers.includes(eventTarget) &&
                !modalTriggers.includes(eventTarget.parentElement)) &&
                !Object.hasOwn(eventTarget.dataset, 'dismiss') &&
                !Object.hasOwn(eventTarget.parentElement.dataset, 'dismiss');
    }

    this.showSingleQMIs = function (e) {
        e.preventDefault();
        hideAllLookupTables();

        var $headerContainer = $(".single-qmi-sidebar-header");
        var $headerContainerMobile = $(".single-qmi-sidebar-header-mobile");
        var $mobilePanel = $(".ProductMapV2-panel-mobile");
        var $desktopPanel = $("[productmapv2panel]");
        var $desktopContainer = $(".ProductMapV2-single-qmi");
        var $mobileContainer = $(".ProductMapV2-single-qmi-mobile");
        var $qmiCountSpans = $("[qmicount]");

        if ($(window).outerWidth() >= 1025) {
            $headerContainer.show();
            $desktopContainer.show();
        }
        else{
            $headerContainerMobile.show();
            $mobileContainer.show();
        }

        $desktopContainer.addClass("ProductMapV2-panel");
        $desktopPanel.removeClass("ProductMapV2-panel").addClass("panel-border");
        $mobilePanel.addClass("single-qmi-active");

        var targetCommunityId = e.target.dataset.communityid;

        var $resetCommunityQMIs = $desktopContainer.find("[data-communityid]:not([data-communityid='" + targetCommunityId + "'])");
        var $resetCommunityMobileQMIs = $mobileContainer.find("[data-communityid]:not([data-communityid='" + targetCommunityId + "'])");
        $resetCommunityQMIs.hide();
        $resetCommunityMobileQMIs.hide();

        setCommunityData(e);
        var $communityQMIs = $desktopContainer.find("[data-communityid='" + targetCommunityId + "']");
        var $communityMobileQMIs = $mobileContainer.find("[data-communityid='" + targetCommunityId + "']");
        $communityQMIs.show();
        $communityMobileQMIs.show();

        $qmiCountSpans.text($communityQMIs.length);

        if (!this.hasExperienceMessage) {
            var $sidebarButton = this.$element.find('.experience-modal-button-sidebar');
            $sidebarButton.attr("href", $sidebarButton.data('href'));
            $sidebarButton.removeAttr('data-toggle');
        }

        this.showLotMap(targetCommunityId);
    };

    this.backToTotalQMIs = function (e) {
        this.handleView.call(this, e, "qmi");
        this.hideLotMap();
    };

    this.hideQMISidebar = function () {
        // hide card container and header container
        $(".ProductMapV2-single-qmi, .ProductMapV2-single-qmi-mobile, .single-qmi-sidebar-header, .single-qmi-sidebar-header-mobile").hide();
        
        $("[productmapv2panel]").addClass("ProductMapV2-panel").removeClass("panel-border");
        $(".ProductMapV2-panel-mobile").removeClass("single-qmi-active");
        $(".ProductMapV2-single-qmi").removeClass("ProductMapV2-panel");
    };

    this.resetPinSelection = function (e) {
        e.preventDefault();
        this.$thisMap.trigger('resetPinSelection');
    };

    this.formatProductType = function(productType) {
        return productType.replaceAll(/[.:()-\s]/g, "").toLowerCase();
    };

    this.handleView = function (event, view, filterValues) {
        var formattedProductType = this.formatProductType(view);
        this.updateMapPins(event, formattedProductType, filterValues);
    
        // this is to center align the No QMI Available message at the SM and XS breakpoints
        if ($('.ProductMapV2').data('qmiCount') == 0 && formattedProductType == 'qmi' && $(window).width() <= 1024) {
            $('.ProductMapV2-panel-mobile').addClass('d-none');
        } else {
            $('.ProductMapV2-panel-mobile').removeClass('d-none');
        }

        hideAllLookupTables();
        this.hideQMISidebar();
        this.hideLotMap();

        Object.values(viewLookupTable).forEach(function (val) {
            val.hide()
        });
        viewLookupTable[view].show();

        var $locationCopySubheader = $('.LocationCopyCard');
        var $communityLocationCopy = $locationCopySubheader.find('.LocationCopyCard__subHeader[data-copy-type="community"]');
        var $qmiLocationCopy = $locationCopySubheader.find('.LocationCopyCard__subHeader[data-copy-type="qmi"');
        if ($locationCopySubheader.length) {
            if(view == 'community') {
                $($communityLocationCopy[0]).show();
                $($qmiLocationCopy[0]).hide();
            } else {
                $($communityLocationCopy[0]).hide();
                $($qmiLocationCopy[0]).show();
            }    
        }
    };
    
    this.handleFilter = function(event, searchResult, filterValues, isFiltered, productType) {
        var resetOrder = !isFiltered;
        var formattedProductType = this.formatProductType(productType);
        
        this.updateMapPins(event, formattedProductType, filterValues);

        // order sidebar cards
        if (formattedProductType === "communities") {
            orderSidebarCardsByCommunityId(".ProductMapCommunitySidebarV2:not(.mobile)", searchResult, resetOrder);
            orderSidebarCardsByCommunityId(".ProductMapCommunitySidebarV2.mobile", searchResult, resetOrder);
        } else if (formattedProductType === "quickmoveins") {
            orderSidebarCardsByCommunityId(".ProductMapTotalQMISidebarV2:not(.mobile)", searchResult, resetOrder);
            orderSidebarCardsByCommunityId(".ProductMapTotalQMISidebarV2.mobile", searchResult, resetOrder);

            orderSingleQMICards(".ProductMapV2-single-qmi", searchResult, resetOrder);
            orderSingleQMICards(".ProductMapV2-single-qmi-mobile", searchResult, resetOrder);
        }
    }

    this.updateMapPins = function (event, productType, filterValues) {
        var mapPinsFilters = {};
        mapPinsFilters.productType = productType;
        mapPinsFilters.brand = this.productLocation.brand;
        mapPinsFilters.state = this.productLocation.state;
        mapPinsFilters.region = this.productLocation.region;

        mapPinsFilters.qmi = productType === 'qmi' || productType === 'quickmoveins';
        
        if (event != null) 
            this.resetPinSelection(event);
        
        if (filterValues) {
            mapPinsFilters.minPrice = filterValues['PR Lower'];
            mapPinsFilters.maxPrice = filterValues['PR Upper'];
            mapPinsFilters.minBedrooms = filterValues['Bedrooms'];
            mapPinsFilters.minBathrooms = filterValues['Bathrooms'];

            filterValues['Home Options'].forEach(function (type) {
                mapPinsFilters[type] = true;
            }.bind(this));
        }

        this.$thisMap.trigger('filterSelected', mapPinsFilters);
    }

    function orderSidebarCardsByCommunityId(selector, searchResult, resetOrder) {
        // orders community cards by community search, or total qmi cards by qmi search

        var originalOrderKey = selector.replaceAll(/[.:()-]/g, "");
        if (resetOrder && _originalOrder[originalOrderKey]) 
            searchResult = _originalOrder[originalOrderKey];

        if (!searchResult) return;

        var communityIds = searchResult.map(function(item) {return item.communityId || item.id});
        var distinctCommunityIds = Array.from(new Set(communityIds));

        var $targetCards = $(selector);
        var $prev = $targetCards.first().prev();
        var $parent = $targetCards.first().parent();

        if (!_originalOrder[originalOrderKey]) {
            _originalOrder[originalOrderKey] = $targetCards.map(function(i, card) { 
                var cardData = $(card).data();
                return { communityId: cardData.communityId || cardData.id } 
            }).toArray();
        }
        
        $targetCards.detach();
        var orderedElements = [];
        distinctCommunityIds.forEach(function(id) {
            var matchingTotalQMICard = $targetCards.filter('[data-community-id="' + id + '"]')[0];
            if (matchingTotalQMICard) 
                orderedElements.push(matchingTotalQMICard);
        });

        if ($prev.length > 0) 
            $(orderedElements).insertAfter($prev);
        else
            $parent.prepend(orderedElements);
    }

    function orderSingleQMICards(selector, filteredQMIs, resetOrder) {
        var originalOrderKey = selector.replaceAll(/[.:()-]/g, "");
        if (resetOrder && _originalOrder[originalOrderKey]) 
            filteredQMIs = _originalOrder[originalOrderKey];

        if (!filteredQMIs) return;

        var lotNumbers = filteredQMIs.map(function(qmi) {return qmi.lotBlock});

        var $container = $(selector);
        var $singleQMICards = $container.children();

        if (!_originalOrder[originalOrderKey]) {
            _originalOrder[originalOrderKey] = $singleQMICards.map(function(i, card) { 
                var cardData = $(card).data();
                return { lotBlock: cardData.lotNumber } 
            }).toArray();
        }

        $singleQMICards.detach();
        var orderedElements = [];
        lotNumbers.forEach(function(number) {
            var matchingSingleQMICard = $singleQMICards.filter('[data-lot-number="' + number + '"]')[0];
            orderedElements.push(matchingSingleQMICard);
        });

        $container.prepend(orderedElements);
    }

    this.updateMapResults = function (e) {
        this.resetPinSelection(e);

        // the location is determined in the map-product-controller-v2
        this.$thisMap.trigger('filterSelected', this.productLocation);
    }

    function reportWindowSize() { // this fires many times on page load, added debounce in above init, real fix will be when carousel v2 stops triggering many window resize events on load. 
        $('.ProductMapV2-panel').animate({ scrollTop: 0 }, 100);
        setCardScrollValues();
        if (_selectedCommunityID != null)
        {
            highlightCardByCommunityID(_selectedCommunityID);
        }
    }

    function highlightCardByCommunityID(communityID) {
        var $mobilePanel = $('.ProductMapV2-panel-mobile');
        var $productPanel = $mobilePanel.is(':visible') ?
            $mobilePanel :
            $('.ProductMapV2-panel');

        var $allActiveCards = $productPanel.find('.v-card.active');
        $allActiveCards.removeClass('active');

        var $selectedCard = $('.ProductMapTotalQMISidebarV2').is(':visible') ?
            $productPanel.find(".ProductMapTotalQMISidebarV2.v-card[data-community-id='" + communityID + "']") :
            $productPanel.find(".ProductMapCommunitySidebarV2.v-card[data-community-id='" + communityID + "']");

        if ($selectedCard) {
            _selectedCommunityID = communityID;
            var currentWindowWidthDifference = $(window).width() - $selectedCard.width();
            var widthOffset = currentWindowWidthDifference / 2;

            $productPanel[0].classList.add('scroll-snap-disabled');
            $productPanel[0].classList.remove('scroll-snap-x', 'scroll-snap-y');

            $selectedCard.addClass('active');
            var scrollOffset = 0;
            var panelTop = $('.ProductMapV2-panel').offset().top;
            var positionTop = $selectedCard.attr('data-top') ? $selectedCard.attr('data-top') : 0;
            var positionLeft = $selectedCard.attr('data-left') ? $selectedCard.attr('data-left') : 0;
            var scrollPosition = $(document).scrollTop();
            var $productFilter = $('#ProductFilterV2');
            var stickyHeaderOffset = 0;

            if ($('.GlobalHeaderV2').hasClass('is-showing')) {
                stickyHeaderOffset = $('.GlobalHeaderV2').height();
            }

            if ($productFilter.hasClass('affix')) {
                stickyHeaderOffset = $productFilter.height();
            }

            if ((scrollPosition + stickyHeaderOffset) > panelTop) {
                scrollOffset = ((scrollPosition - panelTop) + stickyHeaderOffset);
            }

            if ($('.ProductMapV2-panel-mobile').is(':visible')) {
                $productPanel.animate({ scrollLeft: positionLeft - (widthOffset - 5) }, 2000, function(){
                    setTimeout(function () { // Re-enable scroll-snap after animation (extra 100ms buffer)
                        $productPanel[0].classList.add('scroll-snap-x');
                        $productPanel[0].classList.remove('scroll-snap-disabled');
                    }, 2100)
                });
            }
            else {
                $productPanel.animate({ scrollTop: positionTop - scrollOffset }, 2000, function(){
                    setTimeout(function () { // Re-enable scroll-snap after animation (extra 100ms buffer)
                        $productPanel[0].classList.add('scroll-snap-y'); 
                        $productPanel[0].classList.remove('scroll-snap-disabled');
                    }, 2100)
                });
            }
        }
    }

    function highlightFirstCard() {
        setCardScrollValues();
        $('.ProductMapV2-panel-mobile:visible .v-card:visible, .ProductMapV2-panel:visible .v-card:visible')
            .first()
            .trigger("click", { fromApply: true });
        // Include a custom flag to indicate this click originates from 'Apply and Reset'    
    }

    function setCardScrollValues() {
        var $mobilePanel = $('.ProductMapV2-panel-mobile'); 
        var isMobile = $mobilePanel.is(':visible');
        var $scrollContainer = isMobile ? $mobilePanel : $('.ProductMapV2-panel');

        if (isMobile) {
            $scrollContainer.animate({ scrollLeft: 0 }, 100);
            $scrollContainer.find('.v-card:visible').each(function (_, card) {
                $(card).attr('data-left', card.getBoundingClientRect().left + $scrollContainer.scrollLeft());
            });
        } else {
            $scrollContainer.animate({ scrollTop: 0 }, 100);
            $scrollContainer.find('.v-card:visible').each(function (_, card) {
                var cardTop = card.getBoundingClientRect().top;
                var containerTop = $scrollContainer[0].getBoundingClientRect().top;
                var calculatedTop = cardTop - containerTop;
                $(card).attr('data-top', calculatedTop + $scrollContainer.scrollTop());
            });
        }   
    }

    this.setMapPinIcon = function ($element, iconSvgProperties) {
        iconSvgProperties.scale = .75;
        var mapPinProperties = mapPinHelper.getGoogleMapPinObject(iconSvgProperties);
        $element.attr({ 'src': mapPinProperties.url, 'width': mapPinProperties.scaledSize.width, 'height': mapPinProperties.scaledSize.height });
    }

    if (utils.is.mobileOrTabletScreen()) {
        $('.legend-container').removeClass('open');
    }

    $('.legend-container > .header-row').on('click', function () {
        if ($('.legend-container').hasClass('open')) {
            $('.legend-container').removeClass('open');
        } else {
            $('.legend-container').addClass('open');
        }
    })

    this.loadMapLegend = function (e, brandsInSearch) {
        // Show rows if (and only if) the current search contains that brand
        if (brandsInSearch.pulte) {
            if (!brandsInLegend.pulte) {
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteExact'), { brandName: 'Pulte', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteClose'), { brandName: 'Pulte', pinType: 'close' });
                brandsInLegend.pulte = true;
            }
            this.$element.find('.ProductMap__pulte').show();
        } else {
            this.$element.find('.ProductMap__pulte').hide();
        }

        if (brandsInSearch.pulteActiveAdult) {
            if (!brandsInLegend.pulteActiveAdult) {
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteActiveAdultExact'), { brandName: 'Pulte', pinType: 'exact', isActiveAdult: true });
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteActiveAdultClose'), { brandName: 'Pulte', pinType: 'close', isActiveAdult: true });
                brandsInLegend.pulteActiveAdult = true;
            }
            this.$element.find('.ProductMap__pulteActiveAdult').show();
        } else {
            this.$element.find('.ProductMap__pulteActiveAdult').hide();
        }

        if (brandsInSearch.centex) {
            if (!brandsInLegend.centex) {
                this.setMapPinIcon(this.$element.find('.ProductMap__centexExact'), { brandName: 'Centex', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__centexClose'), { brandName: 'Centex', pinType: 'close' });
                brandsInLegend.centex = true;
            }
            this.$element.find('.ProductMap__centex').show();
        } else {
            this.$element.find('.ProductMap__centex').hide();
        }

        if (brandsInSearch.delwebb) {
            if (!brandsInLegend.delwebb) {
                this.setMapPinIcon(this.$element.find('.ProductMap__delwebbExact'), { brandName: 'DelWebb', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__delwebbClose'), { brandName: 'DelWebb', pinType: 'close' });
                brandsInLegend.delwebb = true;
            }
            this.$element.find('.ProductMap__delwebb').show();
        } else {
            this.$element.find('.ProductMap__delwebb').hide();
        }

        if (brandsInSearch.divosta) {
            if (!brandsInLegend.divosta) {
                this.setMapPinIcon(this.$element.find('.ProductMap__divostaExact'), { brandName: 'DiVosta', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__divostaClose'), { brandName: 'DiVosta', pinType: 'close' });
                brandsInLegend.divosta = true;
            }
            this.$element.find('.ProductMap__divosta').show();
        } else {
            this.$element.find('.ProductMap__divosta').hide();
        }

        if (brandsInSearch.jw) {
            if (!brandsInLegend.jw) {
                this.setMapPinIcon(this.$element.find('.ProductMap__jwExact'), { brandName: 'JW', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__jwClose'), { brandName: 'JW', pinType: 'close' });
                brandsInLegend.jw = true;
            }
            this.$element.find('.ProductMap__jw').show();
        } else {
            this.$element.find('.ProductMap__jw').hide();
        }

        if (brandsInSearch.americanwest) {
            if (!brandsInLegend.americanwest) {
                this.setMapPinIcon(this.$element.find('.ProductMap__americanwestExact'), { brandName: 'AmericanWest', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__americanwestClose'), { brandName: 'AmericanWest', pinType: 'close' });
                brandsInLegend.americanwest = true;
            }
            this.$element.find('.ProductMap__americanwest').show();
        } else {
            this.$element.find('.ProductMap__americanwest').hide();
        }
    }

    this.lotClickedAndScroll = function (lotID) {
        var $productPanel = $('.ProductMapV2-panel');
        var isMobile = !$productPanel.is(':visible');
        if (isMobile) {
            $productPanel = $('.ProductMapV2-panel-mobile');
        }

        var $allActiveCards = $productPanel.find('.v-card.active');
        $allActiveCards.removeClass('active');

        var $selectedCard = $productPanel.find("[data-lot-number='" + lotID + "']");
        if ($selectedCard.length) {
            $selectedCard.addClass('active');

            if (isMobile) {
                var $selectedCardInMobile = $productPanel.find("[data-lot-number='" + lotID + "']");
                var $singleQmiMobile = $('.ProductMapV2-single-qmi-mobile');
                $singleQmiMobile.animate({ scrollLeft: $selectedCardInMobile.position().left + $singleQmiMobile.scrollLeft() }, 2000);
            }
            else {
                var scrollOffset = $productPanel.offset().top;
                $productPanel.animate({ scrollTop: $selectedCard.position().top + $productPanel.scrollTop() - scrollOffset }, 2000);
            }
        }
    };

    this.mapPinSelection = function (e, product) {
        var $singleQmiContainer = $(".ProductMapV2-single-qmi:visible, .ProductMapV2-single-qmi-mobile:visible");

        if ($singleQmiContainer.length) {
            this.hideQMISidebar();
            $(".ProductMapTotalQMISidebarV2").show();
        }
        
        highlightCardByCommunityID(product.communityId);
    };

    function hideAllLookupTables() {
        Object.values(viewLookupTable).forEach(function (val) {
            val.hide()
        });
    }

    function setCommunityData(e) {
        var dataset = e.target.dataset;

        var $communityBannerImageContainer = $(".community-banner-image-container");
        $communityBannerImageContainer.find("svg").remove();
        $communityBannerImageContainer.append(dataset.communityBannerLogo);

        var $communityNameHeadline = $(".ProductSummary__headline[communityname]");
        var $anchorCommunityNameTag = $('<a> </a>');
        $anchorCommunityNameTag.attr(
            {
                "href" : "#",
                "class":"experience-modal-button-sidebar",
                "data-target":"#modal-experience",
                "target":"_blank",
                "rel":"noopener",
                "data-toggle":"modal",
                "data-is-active-adult": dataset.isActiveAdult,
                "data-target-brand": dataset.targetBrand,
                "data-href": dataset.href
            }
        ) .html(dataset.communityName);

        $communityNameHeadline.empty();
        $communityNameHeadline.append($anchorCommunityNameTag);
    }
    
    this.showLotMap = function (targetCommunityId) {
        var enableLotMap = _lotMapData.$lotMapContainer[0].getAttribute("data-enable-lot-map").toLowerCase() === "true";

        if (!enableLotMap) return;

        this.setupAlphaVisionApi.call(this, targetCommunityId);
    }

    this.hideLotMap = function () {
        $(".Map-container").show();
        _lotMapData.$lotMapContainer.hide().html("");
        _lotMapData.isLotMapVisible = false;
        _lotMapData.hasQMI = false;
        _lotMapData.isReady = false;
    }

    this.setupAlphaVisionApi = function (targetCommunityId) {
        var lotMapContainer = _lotMapData.$lotMapContainer[0];
        this.mapApi = new AVSiteplan(lotMapContainer.getAttribute("data-builder-guid"),
            targetCommunityId,
            lotMapContainer,
            this.genericCallback.bind(this),
            this.eventCallback.bind(this)
        );
    }

    this.eventCallback = function (response) {
        this.selectQMILot(response.data.lotGroupName, response.data.lotLabel);
        this.lotClickedAndScroll(response.data.lot);
    }

    this.genericCallback = function (response) {
        if (response.datatype.toLowerCase() == "mastermap") {
            this.setSitePlanAndLot.call(this);

            if (!_lotMapData.hasQMI) return;

            this.mapApi.selectMap(_lotMapData.sitePlan);
            this.showLotMapOnSuccess();
        }
        else if (response.datatype.toLowerCase() == "siteplan") {
            this.setSitePlanAndLot.call(this);

            if (!_lotMapData.hasQMI) return;

            var lotArray = this.mapApi.filterLots("Quick Move In").lots;
            for (var i = 0; i < lotArray.length; i++) {
                $("#g" + lotArray[i].lotGroupName + "_l" + lotArray[i].lotNumber).addClass("qmis-lots");
            }

            this.selectQMILot(_lotMapData.groupNumber, _lotMapData.lotLabel);
            this.lotClickedAndScroll(_lotMapData.lotNumber);
            this.showLotMapOnSuccess();
        }
    }

    this.setSitePlanAndLot = function () {
        if (_lotMapData.isReady) return;

        var $qmiCard = $("[qmicontainer]").find(".v-card:visible:first");
        var firstCardLotNumber = $qmiCard.data().lotNumber;

        _lotMapData.hasQMI = this.mapApi.alphamapApiResult.Siteplans.some(function (sitePlan) {
            return sitePlan.LotDetails.some(function (singleLot) {
                return singleLot.status === "Quick Move In";
            });
        });

        if (!_lotMapData.hasQMI) return;

        this.mapApi.alphamapApiResult.Siteplans.some(function (sitePlan) {
            // set first site plan in case no lot matches
            if (!_lotMapData.sitePlan) _lotMapData.sitePlan = sitePlan.SiteplanName;

            return sitePlan.LotDetails.some(function (singleLot) {

                if (singleLot.Lot == firstCardLotNumber) {
                    _lotMapData.groupNumber = singleLot.LotGroupName;
                    _lotMapData.lotLabel = singleLot.LotLabel;
                    _lotMapData.lotNumber = firstCardLotNumber;
                    _lotMapData.sitePlan = sitePlan.SiteplanName;
                    return true;
                }
            });
        });

        _lotMapData.isReady = true;
    };

    this.showLotMapOnSuccess = function () {
        if (!_lotMapData.isLotMapVisible) {
            $(".Map-container").hide();
            _lotMapData.$lotMapContainer.css("display", "grid");
            _lotMapData.isLotMapVisible = true;
        }
    };

    this.selectQMILot = function (groupNumber, lotNumber) {
        var LotClickedId = "g" + groupNumber + "_l" + lotNumber;
        $(".lotPolygon").removeAttr("style");
        var lotMapContainer = _lotMapData.$lotMapContainer[0];
        var brandColor = lotMapContainer.getAttribute("data-highlight-color");
        $("#" + LotClickedId).css("fill", brandColor);
    }
};

module.exports = component(ProductMapV2);
