require('knockout');
require('d3');
require('topojson');

// Bootstrap imports
var dropdown = require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');
var collapse = require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
var transitions = require('bootstrap-sass/assets/javascripts/bootstrap/transition');
var tabs = require('bootstrap-sass/assets/javascripts/bootstrap/tab');
var modal = require('bootstrap-sass/assets/javascripts/bootstrap/modal');
var affix = require('bootstrap-sass/assets/javascripts/bootstrap/affix');
var jqueryVisible = require('jquery-visible');
var clamp = require('./vendor/clamp');
var bootstrapCarousel = require('bootstrap-sass/assets/javascripts/bootstrap/carousel');
var jQueryPrint = require('jQuery.print');

// Jquery-ui imports
//https://jqueryui.com/upgrade-guide/1.12/#official-package-on-npm  (import each widget individually)
var datepicker = require('jquery-ui/ui/widgets/datepicker');

// End

var utils = require('./lib/js/utils.js');
var saveItem = require('./lib/js/save-items.js');
var modals = require('./lib/js/modals.js');
var print = require('./lib/js/print.js');
var startYoutubeAPI = require('./lib/js/youtube.js');
var deeplink = require('./lib/js/deeplink.js');
var savedItemService = require('./lib/js/savedItemsService.js');
var personalizationService = require('./lib/js/personalizationService.js');
var mapDirectionsModal = require('./lib/js/map-directions-modal.js');
var mapDirectionsModalV2 = require('./lib/js/map-directions-modal-v2.js');

var cloudinary = require('cloudinary-jquery-file-upload');
//api key, cloud name read from document meta tags
//https://www.npmjs.com/package/cloudinary-jquery
$.cloudinary.fromDocument();


// Header
//var Header = require('./templates/components/header/header');
//$('.Header').attach(Header, {
//    threshold: 25
//});
 
// GlobalHeader
var GlobalHeader = require('./templates/components/global-header/global-header');
$('.GlobalHeader').attach(GlobalHeader, {
    threshold: 25
});

// GlobalHeaderV2
var GlobalHeaderV2 = require('./templates/components/global-header-v2/global-header-v2');
$('.GlobalHeaderV2').attach(GlobalHeaderV2, {
    threshold: 25
});

// QuickCode
var QuickCode = require('./templates/components/header/header-quick-code-modal');
$('.quickCodeModal').attach(QuickCode);

// HeroHome
var HeroHome = require('./templates/components/hero-home/hero-home.js');
$('.HeroHome').attach(HeroHome);

// Pinterest Links
var PinterestLink = require('./templates/components/pinterest-link/pinterest-link');
$('.PinterestLink').attach(PinterestLink);

// Responsive Media
var ResponsiveMediator = require('./templates/components/responsive-media/responsive-mediator.js');
// If a responsive image exists
// load the mediator
if($('.u-responsiveMedia').length) {
    $('body').attach(ResponsiveMediator);
}

// Carousel
var Carousel = require('./templates/components/carousel/carousel');
$('.Carousel-v1 .Carousel:not(.SlideshowCarousel-main)').attach(Carousel); // Slideshow has its own init

// Carousel V2
var CarouselV2 = require('./templates/components/carousel-v2/carousel-v2');
$('.Carousel-v2').attach(CarouselV2);

// Dual Carousel
var DualCarousel = require('./templates/components/dual-carousel/dual-carousel');
$('.Dual-Carousel').attach(DualCarousel);

// Expanded Carousel V2
var ExpandedCarouselV2 = require('./templates/components/expanded-carousel-v2/expanded-carousel-v2');
$('.ExpandedCarousel-v2').attach(ExpandedCarouselV2);

// Lead Form Slide
// Load this before Slideshow to ensure this doesn't get attached to the cloned slide in Expanded Gallery
var LeadFormSlide = require('./templates/components/lead-form-slide/lead-form-slide.js');
$('.LeadFormSlide').attach(LeadFormSlide);

var Slideshow = require('./templates/components/slideshow/slideshow.js');
$('.Slideshow').attach(Slideshow);

// Maps
var Map = require('./templates/components/map/map-init.js');
if ($('.Map-googleMap').length) {
    var MapInit = new Map();
    MapInit.load();
}

// Fixing Tooltips
var CloseTooltips = require('./templates/components/tooltip/close-tooltips');
$('body').attach(CloseTooltips);

// Tooltips
var Tooltip = require('./templates/components/tooltip/tooltip');
$('.TooltipBtn').attach(Tooltip);

// Popovers
var PopoverBtn = require('./templates/components/tooltip/popover-btn');
$('.PopoverBtn').attach(PopoverBtn);

// Replaced with FindHomes 1/8/17 - J. Shoaf
// Filters
//var Filters = require('./templates/components/filters/filters');
//$('.Filters').attach(Filters);

// Replaced with Product Filter 12/3/18 - J. Shoaf
// Filters
//var FindHomes = require('./templates/components/find-homes/FindHomes');
//$('.FindHomes').attach(FindHomes);
//var FindHomesMapFooter = require('./templates/components/find-homes/FindHomesMapFooter');
//$('.FindHomes__MapFooter').attach(FindHomesMapFooter);

var ProductFilter = require('./templates/components/product-filter/product-filter');
$('.ProductFilter').attach(ProductFilter);

//Location Copy Card
var LocationCopyCard = require('./templates/components/location-copy-card/location-copy-card');
$('.LocationCopyCard').attach(LocationCopyCard);

// Login Editorial
var LoginEditorial = require('./templates/components/login-editorial/login-editorial.js');
$('.LoginEditorial').attach(LoginEditorial);

// Login form
var Login = require('./templates/components/login-form/login-form.js');
$('.LoginModal').attach(Login);

// Logout Form
var AccountLogoutForm = require('./templates/components/account-logout/account-logout-form');
$('.AccountLogoutForm').attach(AccountLogoutForm);

// Navigate Away
var NavigateAway = require('./templates/components/forms/navigate-away');
$('.Navigate-away').attach(NavigateAway);

// Register Your Home
var RegisterHome = require('./templates/components/register-home/register-home.js');
$('.RegisterHome').attach(RegisterHome);

// Register Your Home Editorial
var RegisterYourHomeEditorial = require('./templates/components/account-editorial/register-your-home-editorial.js');
$('.RegisterYourHomeEditorial').attach(RegisterYourHomeEditorial);

// Community Detail Contact form
var CommunityInlineContact = require('./templates/components/contact-community-inline/contact-community-inline.js');
$('.CommunityContact-inline').attach(CommunityInlineContact);

// Community Detail Contact form
var MortgageContact = require('./templates/components/mortgage-contact-form/mortgage-contact-form.js');
$('.MortgageContact').attach(MortgageContact);

// Floor Plan Module
var FloorplanModule = require('./templates/components/floor-plan-module/floor-plan-module');
$('.FloorplanModule').attach(FloorplanModule);

// QMI Floor Plan Module
var QMIFloorPlanModule = require('./templates/components/qmi-floor-plan-module/qmi-floor-plan-module.js');
$('.QMIFloorPlanModule').attach(QMIFloorPlanModule);

// Contact Navigation
var ContactNavigation = require('./templates/components/contact-navigation/contact-navigation');
$('.ContactNavigation').attach(ContactNavigation);

// Community Summary
var CommunitySummary = require('./templates/components/community-summary/community-summary');
$('.CommunitySummary').attach(CommunitySummary);

// Community Hero
var CommunityHero = require('./templates/components/community-hero/community-hero');
$('.CommunityHero').attach(CommunityHero);

// Community 3D Tour
var Community3DTour = require('./templates/components/community-3dtour/community-3dtour.js');
$('.Community3DTour').attach(Community3DTour);

// Home Design Filter
 var HomeDesignFilter = require('./templates/components/home-design-filter/home-design-filter');
$('.HomeDesignFilter').attach(HomeDesignFilter);


// Location Page Filter
var ProductFilterV2 = require('./templates/components/product-filter-v2/product-filter-v2');
$('.ProductFilterV2').attach(ProductFilterV2);

// Location Page Map
var ProductMapV2 = require('./templates/components/product-map-v2/product-map-v2.js');
$('.ProductMapV2').attach(ProductMapV2);

// Home Design Summary
var HomeDesignSummary = require('./templates/components/home-design-summary/home-design-summary');
$('.HomeDesignSummary').attach(HomeDesignSummary);

// Community Series
var CommunitySeries = require('./templates/components/community-series/community-series.js');
$('.CommunitySeries').attach(CommunitySeries);

// Home Design Compact
var HomeDesignCompact = require('./templates/components/home-design-compact/home-design-compact');
$('.HomeDesignCompact').attach(HomeDesignCompact);

// Home Summary Glance
var HomeSummaryGlance = require('./templates/components/home-design-compact/home-summary-glance');
$('.HomeSummaryGlance').attach(HomeSummaryGlance);

// Model Education
var ModelEducation = require('./templates/components/model-education/model-education');
$('.ModelEducation').attach(ModelEducation);

// Inspiration module
var Inspiration = require('./templates/components/inspiration/inspiration.js');
$('.Inspiration').attach(Inspiration);

// Article module
var Article = require('./templates/components/article/article.js');
$('.Article').attach(Article);

// TooltipGlossary have to be below components that are using it - Article
var TooltipGlossary = require('./templates/components/tooltip/popover-btn');
$('.PopoverLink').attach(TooltipGlossary);

// Events and Announcements Module
var EventsAndAnnouncements = require('./templates/components/events-and-announcements/events-and-announcements.js');
$('.EventsAndAnnouncements').attach(EventsAndAnnouncements);

// Amenity Overview Module
var AmenityOverview = require('./templates/components/amenity-overview/amenity-overview.js');
$('.AmenityOverview').attach(AmenityOverview);

// Special Offers V2 Module
var SpecialOffersV2 = require('./templates/components/special-offers-v2/special-offers-v2.js');
$('.SpecialOffersV2').attach(SpecialOffersV2);

// Promotion module even
var PromotionModuleEven = require('./templates/components/promotion-module/promotion-module-even.js');
$('.PromotionModule--odd').attach(PromotionModuleEven);

// Promotion module modal
var PromotionModuleModal = require('./templates/components/promotion-module/promotion-module-modal.js');
$('.PromotionModule-modal').attach(PromotionModuleModal);

// Promotion module single
var PromotionModuleSingle = require('./templates/components/promotion-module/promotion-module-single.js');
$('.PromotionModule').attach(PromotionModuleSingle);

// Financial toolbar
var FinancialToolbar = require('./templates/components/financial-toolbar/financial-toolbar.js');
$('.FinancialToolbar').attach(FinancialToolbar);

// Community toolbar
//var CommunityToolbar = require('./templates/components/community-toolbar/community-toolbar.js');
//$('.CommunityToolbar').attach(CommunityToolbar);

// Community nav
var CommunityNav = require('./templates/components/community-nav/community-nav.js');
$('.CommunityNav').attach(CommunityNav);

// Community Persistent Navigation
var CommunityPersistentNavigation = require('./templates/components/community-persistent-navigation/community-persistent-navigation.js');
$('.CommunityPersistentNav').attach(CommunityPersistentNavigation);

// Article toolbar
var ArticleToolbar = require('./templates/components/article-toolbar/article-toolbar.js');
$('.ArticleToolbar').attach(ArticleToolbar);

// Community Tabs Module
var CommunityTabs = require('./templates/components/community-tabs/community-tabs');
$('.CommunityTabs').attach(CommunityTabs);

// Question and Answer Module
var QuestionAnswer = require('./templates/components/question-answer/question-answer');
$('.QuestionAnswer').attach(QuestionAnswer);

// States Map
var StatesMap = require('./templates/components/states-map/states-map');
$('.StatesMap').attach(StatesMap);

// Type Ahead
var TypeAhead = require('./templates/components/type-ahead/type-ahead');
$('.TypeAhead').attach(TypeAhead);

// Login Page module
var AccountLoginPage = require('./templates/components/account-login/account-login-page');
$('.AccountLoginPage').attach(AccountLoginPage);

// Create Account Page module
var AccountCreatePage = require('./templates/components/account-create/account-create-page');
$('.AccountCreatePage').attach(AccountCreatePage);

// Forgot Password Page module
var ForgotPassword = require('./templates/components/forgot-password/forgot-password');
$('.ForgotPassword').attach(ForgotPassword);

// Account information Page module
var AccountInfo = require('./templates/components/account-information/account-information-form');
$('.AccountInfo').attach(AccountInfo);

// Auto fill the create account form
var AccountCreateAutoFill = require('./templates/components/account-create/account-create-autofill');
$('.AccountCreateAutoFill').attach(AccountCreateAutoFill);

// Account Modal
var AccountModal = require('./templates/components/account-modal/account-modal');
$('.AccountModal').attach(AccountModal);

// Glossary
var Glossary = require('./templates/components/glossary/glossary');
$('.Glossary').attach(Glossary);

// Service Request Wizard
var ServiceRequestWizard = require('./templates/components/service-requests/service-request-wizard');
$('.ServiceRequestWizard').attach(ServiceRequestWizard);

// Service Requests
var ServiceRequests = require('./templates/components/service-requests/service-requests');
$('.ServiceRequests').attach(ServiceRequests);

// Legend
var Legend = require('./templates/components/legend/legend');
$('.Legend').attach(Legend);

// Tile module - Anchor
var AnchorTiles = require('./templates/components/tiles-module/tiles-module-anchor');
$('.AnchorTiles').attach(AnchorTiles);

// Tile module - Tabs
var TabsTiles = require('./templates/components/tiles-module/tiles-module-tabs');
$('.TabsTiles').attach(TabsTiles);

// Tile module - Load More
var TileLoadMore = require('./templates/components/tiles-module/tiles-module-load-more');
$('.Tiles').attach(TileLoadMore);

// Tile module - Carousel
var TilesCarousel = require('./templates/components/tiles-module/tiles-module-carousel');
$('.Tile-carousel').attach(TilesCarousel);

// FacebookForm
var FacebookForm = require('./templates/components/facebook-form/facebook-form');
$('.FacebookForm').attach(FacebookForm);

// AskHomeOwner
var AskHomeOwner = require('./templates/components/ask-homeowner/ask-homeowner');
$( '.Ask-Homeowner-behav' ).attach( AskHomeOwner )

// Review Feedback
var ReviewFeedback = require('./templates/components/ratings-detail-list/ratings-detail-list');
$('.Ratings-list-item').attach(ReviewFeedback);

//Create Design Board
var DesignBoard = require('./templates/components/design-board/design-board');
$('.DesignBoardWrapper').attach(DesignBoard);

// Share Item
var ShareItem = require('./templates/components/share-item/share-item');
$('.ShareItem').attach(ShareItem);

// Review Feedback
var ResetPassword = require('./templates/components/reset-password/reset-password');
$('.ResetPassword').attach(ResetPassword);

// Plan a visit
var PlanAVisit = require('./templates/components/plan-a-visit/plan-a-visit');
$('.PlanAVisit').attach(PlanAVisit);

// Compare Homes
var CompareHomes = require('./templates/components/compare-homes/compare-homes');
$('.CompareHomes').attach(CompareHomes);

// Compare Homes Modal
var CompareHomesModal = require('./templates/components/compare-homes/compare-homes-modal');
$('.CompareHomes-modal').attach(CompareHomesModal);

// Manage Notifications
var ManageNotifications = require('./templates/components/saved-items-page/manage-notifications');
$('.ManageNotifications').attach(ManageNotifications);

// Saved Items Page
var SavedItemsPage = require('./templates/components/saved-items-page/saved-items-page');
$('.SavedItemsPage').attach(SavedItemsPage);

var InspirationTabs = require('./templates/components/inspiration-tabs/inspiration-tabs.js');
$('.InspirationTabs').attach(InspirationTabs);

// Multiple Tooltip
var Tooltipmultiple = require('./templates/components/tooltip-multiple/tooltip-multiple.js');
$('.Tooltip-multiple').attach(Tooltipmultiple);

// Toggle Controls
var ToggleControls = require('./templates/components/toggle-controls/toggle-controls.js');
$('.ToggleControls').attach(ToggleControls);

// Area Features
var AreaFeatures = require('./templates/components/area-features/area-features.js');
$('.AreaFeatures').attach(AreaFeatures);

// About the Area
var AboutTheArea = require('./templates/components/about-the-area/about-the-area.js');
$('.AboutTheArea').attach(AboutTheArea);

// Designed For You
var LifeTestedFeature = require('./templates/components/life-tested-feature/life-tested-feature.js');
$('.LifeTestedFeature').attach(LifeTestedFeature);

// Life Tested Compare
var LifeTestedCompare = require('./templates/components/life-tested-compare/life-tested-compare.js');
$('.LifeTestedCompare').attach(LifeTestedCompare);

// Lifestyle
var Lifestyle = require('./templates/components/lifestyle/lifestyle.js');
$('.Lifestyle').attach(Lifestyle);

// QMI Hero
var QmiHero = require('./templates/components/qmi-hero/qmi-hero.js');
$('.QmiHero').attach(QmiHero);

// QMI Only Module
var QmiOnlyModule = require('./templates/components/qmi-only-module/qmi-only-module.js');
$('.QmiOnlyModule').attach(QmiOnlyModule);

// Lot Map
var LotMap = require('./templates/components/floorplan-lot/floorplan-lot.js');
$('.Lot').attach(LotMap);

// Footer
var Footer = require('./templates/components/footer/footer.js');
$('.Footer').attach(Footer);

// Card
var Card = require('./templates/components/card/card.js');
$('.Card').attach(Card);

// Tabs
var Tabs = require('./templates/components/tabs/tabs.js');
$('.Tabs').attach(Tabs);

// Trio-Gallery
var TrioGallery = require('./templates/components/trio-gallery/trio-gallery.js');
$('.Trio-Gallery').attach(TrioGallery);

// Editorial-Nav
var EditorialNav = require('./templates/components/editorial-nav/editorial-nav.js');
$('.EditorialNav').attach(EditorialNav);

// Editorial-Testimonial
var EditorialTestimonial = require('./templates/components/editorial-testimonial/editorial-testimonial.js');
$('.EditorialTestimonial').attach(EditorialTestimonial);

// Editorial-Tile-Container
var EditorialTileContainer = require('./templates/components/editorial-tile-container/editorial-tile-container.js');
$('.EditorialTileContainer').attach(EditorialTileContainer);

// VerticalTabs
var VerticalTabs = require('./templates/components/vertical-tabs/vertical-tabs.js');
$('.VerticalTabs').attach(VerticalTabs);

// HorizontalTabs
var HorizontalTabs = require('./templates/components/horizontal-tabs/horizontal-tabs.js');
$('.HorizontalTabs:not(.VerticalTabs)').attach(HorizontalTabs);

// Lightbox
var Lightbox = require('./templates/components/lightbox/lightbox.js');
$('.Lightbox').attach(Lightbox);

// Home Collections Side Tabs
var HomeCollectionsSideTabs = require('./templates/components/home-collections-side-tabs/home-collections-side-tabs.js');
$('.HomeCollectionsSideTabs').attach(HomeCollectionsSideTabs);

// Partner-Tile-Container
var PartnerTileContainer = require('./templates/components/partner-tile-container/partner-tile-container.js');
$('.PartnerTileContainer').attach(PartnerTileContainer);

// Product Summary
var ProductSummary = require('./templates/components/product-summary/product-summary.js');
$('.ProductSummary').attach(ProductSummary);

// Product Summary
var ProductSummaryV2 = require('./templates/components/product-summary-v2/product-summary-v2.js');
$('.ProductSummary').attach(ProductSummaryV2);

// Location Selection
var LocationSelection = require('./templates/components/location-selection/location-selection.js');
$('.LocationSelection').attach(LocationSelection);

// Product Map
var ProductMap = require('./templates/components/product-map/product-map.js');
$('.ProductMap').attach(ProductMap);

// Experience Message
var ExperienceMessage = require('./templates/components/experience-message/experience-message.js');
$('.ExperienceMessage').attach(ExperienceMessage);

// Hers Modal
var HersModal = require('./templates/components/hers-modal/hers-modal.js');
$('.HersModal').attach(HersModal);

// Grid
var Grid = require('./templates/components/grid/grid.js');
$('.GridContainer').attach(Grid);

// Sitemap
var Sitemap = require('./templates/components/sitemap/sitemap.js');
$('.Sitemap').attach(Sitemap);

// Hers Score Meter
var HersScoreMeter = require('./templates/components/hers-score-meter/hers-score-meter.js');
$('.HersScoreMeter').attach(HersScoreMeter);

// Personalized Nav
var PersonalizedNav = require('./templates/components/personalized-nav/personalized-nav.js');
$('.PersonalizedNav').attach(PersonalizedNav);

// Cloudinary Video
var CloudinaryVideo = require('./templates/components/cloudinary-video/cloudinary-video.js');
$('.CloudinaryVideo').attach(CloudinaryVideo);

// QMI Summary List With Title
var QMISummaryListWithTitle = require('./templates/components/qmi-summary/qmi-summary-list-with-title.js');
$('.QMISummaryListWithTitle').attach(QMISummaryListWithTitle);

// Confirmation Review
var ConfirmationReview = require('./templates/components/confirmation-review/confirmation-review.js');
$('.ConfirmationReview').attach(ConfirmationReview);

// Appointment Scheduling Modal
var AppointmentSchedulingModal = require('./templates/components/appointment-scheduling-modal/appointment-scheduling-modal.js');
$('.AppointmentSchedulingModal').attach(AppointmentSchedulingModal);

// Global Lead Form
var GlobalLeadForm = require('./templates/components/global-lead-form/global-lead-form.js');
$('.GlobalLeadForm').attach(GlobalLeadForm);

// Global Inline Lead Form
var GlobalInlineLeadForm = require('./templates/components/global-inline-lead-form/global-inline-lead-form.js');
$('.GlobalInlineLeadForm').attach(GlobalInlineLeadForm);

// Global Modal Lead Form
var GlobalModalLeadForm = require('./templates/components/global-modal-lead-form/global-modal-lead-form.js');
$('.GlobalModalLeadForm').attach(GlobalModalLeadForm);

// Edit My Account
var EditMyAccount = require('./templates/components/edit-my-account/edit-my-account.js');
$('.EditMyAccount').attach(EditMyAccount);

// Construction Progress
var ConstructionProgress = require('./templates/components/construction-progress/construction-progress.js');
$('.ConstructionProgress').attach(ConstructionProgress);

var RegisterYourHomeLite = require('./templates/components/register-your-home-lite/register-your-home-lite.js');
$('.RegisterYourHomeLite').attach(RegisterYourHomeLite);

// Sticky Bottom Bar
var StickyBottomBar = require('./templates/components/sticky-bottom-bar/sticky-bottom-bar.js');
$('.StickyBottomBar').attach(StickyBottomBar);

// Hero PFS Overlay
var HeroPFSOverlay = require('./templates/components/hero-pfs-overlay/hero-pfs-overlay.js');
$('.Hero-Overlay').attach(HeroPFSOverlay);

// Community Overview
var CommunityOverview = require('./templates/components/community-overview/community-overview.js');
$('.CommunityOverview').attach(CommunityOverview);

// Find a Home Filter
var FindAHomeFilter = require('./templates/components/find-a-home-filter/find-a-home-filter.js');
$('.FindAHomeFilter').attach(FindAHomeFilter);

// Community Inline Lead Form
var CommunityInlineLeadForm = require('./templates/components/community-inline-lead-form/community-inline-lead-form.js');
$('.CommunityInlineLeadForm').attach(CommunityInlineLeadForm);
// Innovation Card
var InnovationCard = require('./templates/components/innovation-card/innovation-card.js');
$('.InnovationCard').attach(InnovationCard);

// Alpha Vision Map
var AlphaVisionMap = require('./templates/components/alpha-vision-map/alpha-vision-map.js');
$('.AlphaVisionMap').attach(AlphaVisionMap);

// Compare
var Compare = require('./templates/components/compare/compare');
$('.CompareTray').attach(Compare);

var CompareResults = require('./templates/components/compare/compare-results/compare-results');
$('.CompareResults').attach(CompareResults);

var CompareErrorModal = require('./templates/components/compare/compare-error-modal/compare-error-modal.js');
$('.CompareErrorModal').attach(CompareErrorModal);

// Plan Overview
var PlanOverview = require('./templates/components/plan-overview/plan-overview.js');
$('.PlanOverview').attach(PlanOverview);

var PlanInteractiveTool = require('./templates/components/plan-interactive-tool/plan-interactive-tool.js');
$('.PlanInteractiveTool').attach(PlanInteractiveTool);

var LeavePageModal = require('./templates/components/leave-page-modal/leave-page-modal.js');
$('.LeavePageModal').attach(LeavePageModal);

var OSSVIPCard = require('./templates/components/ossvipcard/ossvipcard.js');
$('.OSSVIP').attach(OSSVIPCard);

// process deeplink for page load
deeplink.processDeeplink();

$(window).trigger("components.loaded");

$.cloudinary.responsive();

var CDPEvents = require('./lib/js/cdp-events.js');

var CDPPersonalize = require('./lib/js/cdp-personalize.js');