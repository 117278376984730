/**
 * Adobe Launch Analytics Client
 */
var AdobeLaunch = {
    listings: [],

    // Form Event Methods
    pushToDataLayer: function (eventObject) {
        window.appEventData = window.appEventData || [];
        window.appEventData.push(eventObject);
    },

    pushFormViewedEvent: function (formID, formType, formName, communityID, communityBrand, moduleName) {
        this.pushFormEvent(this.FORM_VIEWED_EVENT_NAME, formID, formType, formName, communityID, communityBrand, null, null, moduleName);
    },

    pushFormStartedEvent: function (formID, formType, formName, communityID, communityBrand, moduleName) {
        this.pushFormEvent(this.FORM_STARTED_EVENT_NAME, formID, formType, formName, communityID, communityBrand, null, null, moduleName);
    },

    pushFormSubmitSucceededEvent: function (formID, formType, formName, communityID, communityBrand, edhRegionId, formAppointments, moduleName) {
        this.pushFormEvent(this.FORM_SUBMIT_SUCCEEDED_EVENT_NAME, formID, formType, formName, communityID, communityBrand, edhRegionId, formAppointments, moduleName);
    },

    pushFormSubmitFailedEvent: function (formID, formType, formName, communityID, communityBrand) {
        this.pushFormEvent(this.FORM_SUBMIT_FAILED_EVENT_NAME, formID, formType, formName, communityID, communityBrand);
    },

    pushFormEvent: function (eventName, formID, formType, formName, communityID, communityBrand, edhRegionId, formAppointments, moduleName) {
        if (!formID || !formName || !formType)
            return;

        var formEvent = {
            event: eventName,
            module: moduleName,
            form: {
                formID: formID,
                formType: formType,
                formName: formName
            }
        };

        if (communityID) {
            formEvent.community = createCommunityLaunchObject(communityID, communityBrand);
        }

        if (edhRegionId) {
            formEvent.region = { regionID: edhRegionId };
        }

        if (formAppointments && formAppointments > 0) {
            formEvent.form.formAppointments = formAppointments
        }

        this.pushToDataLayer(formEvent);
    },

    pushUserEvent: function (eventName, userID) {
        if (!eventName)
            return;

        var userEvent = { event: eventName };

        if (userID) {
            userEvent.user = { custKey: userID };
        }

        this.pushToDataLayer(userEvent);
    },

    pushTHOOrderConfirmationEvent: function (eventName, contractNumber) {
        var orderConfirmationEvent = {
            event: eventName,
            order: {
                contractNumber: contractNumber
            }
        };
        this.pushToDataLayer(orderConfirmationEvent);
    },

    pushListingClickedEvent: function (eventName) {
        if (eventName && this.listings && this.listings.length) {
            var listingEvent = { event: eventName };
            var listing = { listing: this.listings };
            listingEvent.listingItemClicked = listing;

            this.pushToDataLayer(listingEvent);
            this.listings = []; // Clear listings array to prepare for the next listing event.
        }
    },

    pushListingDisplayedEvent: function ($elements, eventName, filterData, exactMatchCount) {
        if ($elements && $elements.length && eventName) {
            var _this = this;

            $elements.each(function (e) {
                if (this.dataset) {
                    var communityId = this.dataset.analyticsCommunityId;
                    var communityBrand = this.dataset.analyticsCommunityBrand;
                    var itemPosition = this.dataset.analyticsItemPosition;
                    var planId = null;
                    var qmiId = null;

                    if (eventName === AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME) {
                        planId = this.dataset.analyticsPlanId;
                        qmiId = this.dataset.analyticsQmiId;
                    }

                    _this.addListing(communityId, communityBrand, planId, qmiId, itemPosition);
                }
            });

            var listingEvent = { event: eventName };
            var filtersApplied = getFiltersApplied(filterData);

            listingEvent.listingDisplayed = {
                filtersApplied: filtersApplied,
                listing: this.listings
            };

            if (exactMatchCount) {
                listingEvent.listingDisplayed.exactMatchResultsCount = exactMatchCount;
            }

            this.pushToDataLayer(listingEvent);
            this.listings = []; // Clear listings array to prepare for the next listing event.
        }
    },

    pushItemSavedEvent: function (communityID, communityBrand, planID, qmiID, eventName) {
        if (communityID && communityBrand) {
            var itemSavedEvent = {
                event: eventName
            };

            itemSavedEvent.community = createCommunityLaunchObject(communityID.toString(), communityBrand);

            if (planID) {
                var plan = {};
                plan.planInfo = { planID: planID.toString() };

                if (qmiID) {
                    plan.QMI = { QMIID: qmiID.toString() };
                }

                // Adobe requires this to be an array even though we'll only ever pass one plan
                itemSavedEvent.plan = [
                    plan
                ];
            }

            this.pushToDataLayer(itemSavedEvent);
        }
    },

    pushItemCompareEvent: function (communityID, communityBrand, itemsCompared, eventName) {

        if (communityID && communityBrand) {

            var itemCompareEvent;

            if (eventName == this.PLAN_COMPARED_EVENT_NAME) {
                itemCompareEvent = {
                    event: eventName,
                    community: createCommunityLaunchObject(communityID.toString(), communityBrand)
                };

                itemCompareEvent.plans = [];

                for (var i = 0; i < itemsCompared.length; i++) {
                    var plan = {};
                    plan.planInfo = { planID: itemsCompared[i].toString() };
                    itemCompareEvent.plans.push(plan)
                }
            }
            else if (eventName == this.QMI_COMPARED_EVENT_NAME) {
                itemCompareEvent = {
                    event: eventName,
                    community: createCommunityLaunchObject(communityID.toString(), communityBrand)
                };

                itemCompareEvent.qmis = [];

                for (var j = 0; j < itemsCompared.length; j++) {
                    var qmi = {};
                    qmi.qmiInfo = { qmiID: itemsCompared[j][0].toString() };
                    qmi.planInfo = { planID: itemsCompared[j][1].toString() };
                    itemCompareEvent.qmis.push(qmi)
                }
            }
            else if (eventName == this.COMMUNITY_COMPARED_EVENT_NAME) {
                itemCompareEvent = {
                    event: eventName,
                    Community: []
                };

                var list = [];
                list.push({ communityBrand: communityBrand, communityID: communityID });
                itemCompareEvent.Community = list;
            }

            this.pushToDataLayer(itemCompareEvent);
        }
    },

    maxComparedReached: function (eventName) {
        this.pushToDataLayer({ event: eventName });
    },

    addListing: function (communityID, communityBrand, planID, qmiID, itemPosition) {
        if (communityID) {
            var listing = {};

            if (itemPosition) {
                var itemPositionNumber = parseInt(itemPosition);

                // ensure the number isn't NaN
                if (itemPositionNumber) {
                    listing.itemPosition = itemPositionNumber;
                }
            }

            listing.community = createCommunityLaunchObject(communityID.toString(), communityBrand);

            if (planID) {
                listing.planInfo = { planID: planID.toString() };

                if (qmiID) {
                    listing.QMI = { QMIID: qmiID.toString() };
                }
            }
            this.listings.push(listing);
        }
    },

    getFormNameFromId: function (dataFormId) {
        switch (dataFormId) {
            case 'Contactnav-financing':
                return this.FORM_FINANCING_NAME;
            case 'Contactnav-community':
            case 'Contactnav-community-specific':
                return this.FORM_REQUEST_INFO_NAME;
            case 'Contactnav-homeowner-question':
                return this.FORM_HOMEOWNER_QUESTION_NAME;
            default:
                return this.FORM_SERVICE_REQUEST_NAME;
        }
    },

    sendListingClickedEventFromCTA: function ($element) {
        if ($element && $element.data('analytics-listing-type')) {
            var listingType = $element.data('analytics-listing-type');
            var itemPosition = $element.closest('[data-analytics-item-position]').data('analytics-item-position');

            var communityID = $element.closest('[data-analytics-community-id]').data('analytics-community-id');
            var communityBrand = $element.closest('[data-analytics-community-brand]').data('analytics-community-brand');
            var planID = (listingType === 'plan' || listingType === 'qmi') ? $element.closest('[data-analytics-plan-id]').data('analytics-plan-id') : null;
            var qmiID = (listingType === 'qmi') ? $element.closest('[data-analytics-qmi-id]').data('analytics-qmi-id') : null;

            this.addListing(communityID, communityBrand, planID, qmiID, itemPosition);

            var listingEventName = (listingType === 'community') ? AdobeLaunch.COMMUNITY_LISTING_ITEM_CLICKED_EVENT_NAME : AdobeLaunch.PLAN_LISTING_ITEM_CLICKED_EVENT_NAME;
            this.pushListingClickedEvent(listingEventName);
        }
    },

    pushVideoStatusChangedEvent: function (wrapperElement, eventName) {
        if (!wrapperElement) return;

        var $wrapper = $(wrapperElement);
        var youtubeId = $wrapper.find('iframe').data('youtube-id');
        var youtubeAnalytics = youtubeId ? youtubeId + " Ended" : null;

        var videoEvent = {
            event: eventName,
            linkInfo: {
                lead: youtubeAnalytics,
                leadBrand: $wrapper.closest('[data-analytics-community-brand]').data('analytics-community-brand'),
                leadCommunity: $wrapper.closest('[data-analytics-community-id]').data('analytics-community-id'),
                linkId: youtubeAnalytics,
                linkRegion: $wrapper.closest('[data-analytics-zone]').data('analytics-zone'),
                linkType: 'none'
            }
        };

        // Conditionally add keys if they are defined
        var planId = $wrapper.closest('[data-analytics-plan-id]').data('analytics-plan-id');
        if (planId !== undefined) {
            videoEvent.linkInfo.planId = planId;
        }

        var qmiId = $wrapper.closest('[data-analytics-qmi-id]').data('analytics-qmi-id');
        if (qmiId !== undefined) {
            videoEvent.linkInfo.qmiId = qmiId;
        }

        // Push to the data layer
        this.pushToDataLayer(videoEvent);
    },

    // (WARNING: Changing these WILL affect Adobe Launch Analytics reporting)
    // Form Event Data Constants
    FORM_VIEWED_EVENT_NAME: 'Form Viewed',
    FORM_STARTED_EVENT_NAME: 'Form Started',
    FORM_SUBMIT_SUCCEEDED_EVENT_NAME: 'Form Submission Succeeded',
    FORM_SUBMIT_FAILED_EVENT_NAME: 'Form Submission Failed',
    FORM_REQUEST_INFO_NAME: 'Request Information',
    FORM_FINANCING_NAME: 'Financing',
    FORM_HOMEOWNER_QUESTION_NAME: 'Homeowner Question',
    FORM_SERVICE_REQUEST_NAME: 'Service Request',
    FORM_HOME_REGISTRATION_NAME: 'Home Registration',
    FORM_UPDATE_PASSWORD_NAME: 'Update Password',
    FORM_UPDATE_ACCOUNT_INFO_NAME: 'Update Account Information',
    FORM_PROFILE_PICTURE_UPLOADED: 'Profile Photo Uploaded',
    FORM_APPOINTMENT_SCHEDULING_NAME: 'Appointment Scheduling',
    FORM_GLOBAL_ID: 'Global',
    FORM_COMMUNITY_ID: 'Community',
    FORM_INLINE_TYPE: 'Inline',
    FORM_MODAL_TYPE: 'Modal',

    // User Event Constants
    USER_REGISTERED_EVENT_NAME: 'User Registered',
    USER_SIGNED_IN_EVENT_NAME: 'User Signed In',
    USER_SIGNED_OUT_EVENT_NAME: 'User Signed Out',

    // Listing Event Constants
    PLAN_LISTING_ITEM_CLICKED_EVENT_NAME: 'Plan Listing Item Clicked',
    COMMUNITY_LISTING_ITEM_CLICKED_EVENT_NAME: 'Community Listing Item Clicked',
    PLAN_LISTING_DISPLAYED_EVENT_NAME: 'Plan Listing Displayed',
    COMMUNITY_LISTING_DISPLAYED_EVENT_NAME: 'Community Listing Displayed',

    // Save Event Constants
    COMMUNITY_SAVED_EVENT_NAME: 'Community Saved',
    PLAN_SAVED_EVENT_NAME: 'Plan Saved',
    INTERACTIVE_FLOOR_PLAN_SAVED_EVENT_NAME: 'Interactive FloorPlan Saved',

    // Compare Event Constants
    COMMUNITY_COMPARED_EVENT_NAME: 'Compare Communities',
    PLAN_COMPARED_EVENT_NAME: 'Compare Plans',
    QMI_COMPARED_EVENT_NAME: 'Compare QMIs',
    MAX_COMPARED_REACHED: 'Max Compared Reached',

    // THO Event Constants
    THO_ORDER_CONFIRMATION_EVENT_NAME: 'Order Confirmation',

    // Map Events
    MAP_PIN_CLICK: 'Community Map Pin Selected',

    // Youtube Video Event
    VIDEO_STATUS_CHANGED_EVENT_NAME: 'Video Status Changed'
}

createCommunityLaunchObject = function (communityID, communityBrand) {
    if (communityID) {
        var community = { communityID: communityID };
        if (communityBrand) {
            community.communityBrand = communityBrand;
        } else {
            community.communityBrand = window.Pulte.Brand.replace("-", " ");
        }
        return community;
    }
}

getFiltersApplied = function (filterData) {
    var filtersApplied = [];

    // Home types mapping
    var homeTypeMapping = {
        "singlefamilyhome": "Single Family Home",
        "townhome": "Townhome",
        "condominium": "Condominium",
        "carriagehome": "Carriage Home",
        "gardenhome": "Garden Home",
        "villa": "Villa"
    };

    if (filterData) {
        if (filterData.brand) {
            filtersApplied.push({
                filterKey: "brand",
                filterValue: filterData.brand
            });
        }

        if (filterData.state) {
            filtersApplied.push({
                filterKey: "state",
                filterValue: filterData.state
            });
        }

        if (filterData.region) {
            filtersApplied.push({
                filterKey: "region",
                filterValue: filterData.region
            });
        }

        if (filterData.cityNames) {
            filtersApplied.push({
                filterKey: "cityNames",
                filterValue: filterData.cityNames
            });
        }

        if (filterData.minPrice || filterData.minPrice === 0) {
            filtersApplied.push({
                filterKey: "minPrice",
                filterValue: filterData.minPrice.toString()
            });
        }

        if (filterData.maxPrice || filterData.maxPrice === 0) {
            filtersApplied.push({
                filterKey: "maxPrice",
                filterValue: filterData.maxPrice.toString()
            });
        }

        if (filterData.minSqft || filterData.minSqft === 0) {
            filtersApplied.push({
                filterKey: "minSqft",
                filterValue: filterData.minSqft.toString()
            });
        }

        if (filterData.maxSqft || filterData.maxSqft === 0) {
            filtersApplied.push({
                filterKey: "maxSqft",
                filterValue: filterData.maxSqft.toString()
            });
        }

        if (filterData.minBedrooms || filterData.minBedrooms === 0) {
            filtersApplied.push({
                filterKey: "minBedrooms",
                filterValue: filterData.minBedrooms.toString()
            });
        }

        if (filterData.maxBedrooms || filterData.maxBedrooms === 0) {
            filtersApplied.push({
                filterKey: "maxBedrooms",
                filterValue: filterData.maxBedrooms.toString()
            });
        }

        if (filterData.minBathrooms || filterData.minBathrooms === 0) {
            filtersApplied.push({
                filterKey: "minBathrooms",
                filterValue: filterData.minBathrooms.toString()
            });
        }

        if (filterData.maxBathrooms || filterData.maxBathrooms === 0) {
            filtersApplied.push({
                filterKey: "maxBathrooms",
                filterValue: filterData.maxBathrooms.toString()
            });
        }

        if (filterData.homeType === 1 || filterData.homeType === 2) {
            filtersApplied.push({
                filterKey: "homeType",
                filterValue: filterData.homeType === 1 ? "Condos/Townhomes" : "Single Family Homes"
            });
        }

        // Special case: Home types array with replacement 
        if (filterData.homeTypes && Array.isArray(filterData.homeTypes)) {
            var homeTypeValues = filterData.homeTypes.map(function (homeType) {
                return homeTypeMapping[homeType] || homeType;
            });
            filtersApplied.push({
                filterKey: "homeTypes",
                filterValue: homeTypeValues.join(", ")
            });
        }
    }

    return filtersApplied;
}

// Click listener to catch then send Adobe Launch events for any CTA marked with a "data-analtytics-listing-cta" attribute.
$(document).on('click', '[data-analytics-listing-cta]', function (event) {
    var $eventTarget = $(event.target);
    if ($eventTarget) {
        AdobeLaunch.sendListingClickedEventFromCTA($eventTarget);
    }
});

module.exports = AdobeLaunch;
