var component = require('../../../lib/js/component.js');

function QMIFloorplanModule() {

    this.initialize = function(element, options) {
        this.main = $(element);
        this.bbIndex = 0;
        this.floorIndex = 0;

        var $bbOption = this.main.find('.bb-option');
        var $floorOption = this.main.find('.floor-option');
        $bbOption.on('click touch', this.setBedBathOption.bind(this));
        $floorOption.on('click touch', this.setFloorOption.bind(this));


        var $bbAccordionSelection = this.main.find('.bed-bath-accordion .accordion-selection');
        var $floorAccordionSelection = this.main.find('.floor-accordion .accordion-selection');
        $bbAccordionSelection.on('click touch', this.toggleBedBathAccordion.bind(this));
        $floorAccordionSelection.on('click touch', this.toggleFloorAccordion.bind(this));
    }

    this.setBedBathOption = function(e) {
        var $el = $(e.currentTarget);
        // if it's not already active
        if (!$el.hasClass('is-active')) {
            // toggle active state
            this.main.find('.bb-option.is-active').removeClass('is-active');
            // set selected bed bath based on clicked element
            this.bbIndex = $el.data().index;
            this.main.find('.bb-option[data-index=' + this.bbIndex + ']').addClass('is-active');
            // update mobile accordion selection text
            this.main.find('.accordion-selection-text.bb-option-selection').text($el.text().trim());
            // reset selected floor to first option in list
            this.floorIndex = 0;
            // show associated floor options 
            this.updateFloorOptions();
            // filter image accordingly
            this.filterFloorImage();
        }
    }

    this.setFloorOption = function(e) {
        var $el = $(e.currentTarget);
        if (!$el.hasClass('is-active')) {
            this.main.find('.floor-option.is-active').removeClass('is-active');
            this.floorIndex = $el.data().index;
            this.main.find('.floor-option[data-index=' + this.floorIndex + ']').addClass('is-active');
            this.main.find('.accordion-selection-text.floor-option-selection').text($el.text().trim());

            this.filterFloorImage();
        }
    }

    this.updateFloorOptions = function(e) {
        this.main.find('.floor-options-inner.is-active').removeClass('is-active');
        this.main.find('.floor-option.is-active').removeClass('is-active');
        this.main.find('.floor-options-inner[data-index=' + this.bbIndex + ']').addClass('is-active');
        this.main.find('.floor-options-inner.is-active .floor-option[data-index=' + this.floorIndex + ']').addClass('is-active');
        var $selectedFloor = this.main.find('.floor-accordion .floor-option.is-active');
        this.main.find('.accordion-selection-text.floor-option-selection').text($selectedFloor.text().trim());
    }

    this.filterFloorImage = function(e) {
        this.main.find('.bed-bath-container.is-active').removeClass('is-active');
        this.main.find('.floor-container.is-active').removeClass('is-active');
        this.main.find('.bed-bath-container[data-index=' + this.bbIndex + ']').addClass('is-active');
        this.main.find('.floor-container[data-index=' + this.floorIndex + ']').addClass('is-active');
    }

    this.toggleBedBathAccordion = function(e) {
        this.toggleAccordion(e);
    }

    this.toggleFloorAccordion = function(e) {
        this.toggleAccordion(e);
    }

    this.toggleAccordion = function(e) {
        var $el = $(e.currentTarget);
        var $accordion = $el.closest('.accordion');
        $accordion.toggleClass('expanded');
    }

}

module.exports = component(QMIFloorplanModule);