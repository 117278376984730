var component = require('../../../lib/js/component.js');
var utils = require("../../../lib/js/utils.js");
var moreToggle = require('../../../templates/shared/more-links.js');

function ProductSummaryV2() {

    var _initCount = 0;
    var _originalOrder = {};

    var _galleryLookupTable = {
        "community": $(".ProductSummaryGalleryv2"),
        "qmi": $(".ProductSummaryQMIGalleryv2")
    };
    
    var _expandMethods = setupExpandGalleryCards();

    var _view = "";

    this.initialize = function (element, options) {
        var $window = $(window);
        this.lastItem = null;
        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;
        this.setExperienceModalButtons();

        // Fix issue of community opening in experience modal
        $('.experience-modal-button').on('click', function(e) {
            e.preventDefault();
            $('#modal-experience').modal('show');
        });

        // ...More toggle for communities
        moreToggle.initialize(this.$element);

        // See More toggle for QMIs
        $('.more-button').off().on("click", function() {
            var $this = $(this);
            var $parent = $this.parent();
            if($parent.hasClass('collapse-more')) {
                $parent.removeClass('collapse-more');
                $this.text('See Less');       
            }
            else {
                $parent.addClass('collapse-more');
                $this.text('See More');     
            }
        });
        this.handleGalleryView(null,"community");
        
        if (_initCount == 0) { // only setup once
            _initCount += 1;
            this.$element.on("pfv2-filter-change", this.handleFilter.bind(this));
            this.$element.on("pf-view-change", this.handleGalleryView.bind(this));

            _galleryLookupTable["community"].find(".community-expand").on("click", utils.debounce(_expandMethods.expandCommunities, 250, false));
            _galleryLookupTable["qmi"].find(".qmi-expand").on("click", utils.debounce(_expandMethods.expandQMIs, 250, false));
        }

        var communitiesCount = $('.ProductSummary__community.card').length;
        var qmiCount = $('.QMIGalleryCard').length;
        $('#ProductFilterV2').trigger('product-filter-v2-initialized', [communitiesCount, qmiCount]);
        $window.on("resize", utils.debounce(setupCloseMatchBreak, 250, false));
    }

    // If the experience modal is not on the page, the button clicks should navigate the user to the appropriate community/qmi page without the experience message popup.
    this.setExperienceModalButtons = function() {
        var $viewCommunityButtons = this.$element.find('.ProductSummary__buttons [data-target="#modal-experience"]');

        if (!this.hasExperienceMessage) {
            $viewCommunityButtons.each( function() {
                var $this = $(this);
                $this.attr("href", $this.data('href'));
                $this.removeAttr('data-toggle');
            });
        }
    }

    this.handleGalleryView = function(event,view) {
        Object.values(_galleryLookupTable).forEach(function(val){
            val.hide()
        });
        _view = view;
        _galleryLookupTable[view].show();
    }

    this.handleFilter = function(event, searchResult, filterValues, isFiltered, productType) {
        var resetOrder = !isFiltered;

        var formattedProductType = productType.replaceAll(/[.:()-\s]/g, "").toLowerCase();
        if (formattedProductType === "communities") {
            this.orderCardsByAttr(".ProductSummary__community.card", "data-community-id", searchResult, resetOrder);
        }
        else if (formattedProductType === "quickmoveins") {
            this.orderCardsByAttr(".QMIGalleryCard", "data-lot-number", searchResult, resetOrder);
            this.orderCardsByAttr(".QMIGridCard", "data-lot-number", searchResult, resetOrder);
        }

        _expandMethods.resetSetup();
        
        setupCloseMatchBreak();
    }

    this.orderCardsByAttr = function (selector, attrSelector, searchResult, resetOrder) {
        var communityCaseProp = 'id';
        var qmiCaseProp = 'lotBlock';
        var resetCaseProp = 'attrValue';

        var originalOrderKey = selector.replaceAll(/[.:()-]/g, "");
        if (resetOrder && _originalOrder[originalOrderKey]) 
            searchResult = _originalOrder[originalOrderKey];

        if (!searchResult) return;

        var $targetCards = $(selector);
        var $prev = $targetCards.first().prev();
        var $parent = $targetCards.first().parent();

        if (!_originalOrder[originalOrderKey]) {
            _originalOrder[originalOrderKey] = $targetCards.map(function(i, card) { 
                var cardData = $(card).data();
                return { attrValue: cardData.lotNumber || cardData.communityId } 
            }).toArray();
        }

        $targetCards.detach();
        var orderedElements = [];
        var handleExactCloseMatchClasses = setupExactCloseClassHandler();
        searchResult.forEach(function(item) {
            var attrValue = item[communityCaseProp] || item[qmiCaseProp] || item[resetCaseProp];
            var matchingCard = $targetCards.filter('[' + attrSelector + '="' + attrValue + '"]')[0];

            if (!matchingCard) return;

            handleExactCloseMatchClasses(matchingCard, item);

            orderedElements.push(matchingCard);
        });

        if ($prev.length > 0) 
            $(orderedElements).insertAfter($prev);
        else
            $parent.prepend(orderedElements);

    };

    function setupExactCloseClassHandler() {
        var exactHeaderAdded = false;
        var closeHeaderAdded = false;
        var exactHeader = 'exact-match-header';
        var closeHeader = 'close-match-header';

        return function (matchingCard, item) {
            var isFirstMatch = false;

            matchingCard.classList.remove(exactHeader, closeHeader);
    
            if (item.hasOwnProperty('isExactMatch')) {
                if (item.isExactMatch) {
                    if (!exactHeaderAdded) {
                        isFirstMatch = true;
                        exactHeaderAdded = true;
                    }
                } else {
                    if (!closeHeaderAdded) {
                        isFirstMatch = true;
                        closeHeaderAdded = true;
                    }
                }
            }
            
            if (isFirstMatch) {
                if (item.isExactMatch)
                    matchingCard.classList.add(exactHeader);
                else
                    matchingCard.classList.add(closeHeader);
            } 
        }
    }

    function setupCloseMatchBreak() {
        var containerClass = "";
        if (_view == "community") containerClass = ".ProductSummaryV2__communityContainer";
        if (_view == "qmi") containerClass = ".ProductSummaryV2__qmiContainer";

        var $exactMatchHeader = $(containerClass + " .exact-match-label");
        var $closeMatchHeader = $(containerClass + " .close-match-label");
        var $exactMatchCards = $(".exact-match-header:visible");
        if ($exactMatchCards.length) {
            $exactMatchHeader.insertBefore($exactMatchCards.first());
            $exactMatchHeader.removeClass("d-none");
        } else {
            $exactMatchHeader.addClass("d-none");
        }

        var $closeMatchCards = $(".close-match-header:visible");
        if ($closeMatchCards.length) {
            $closeMatchHeader.insertBefore($closeMatchCards.first());
            $closeMatchHeader.removeClass("d-none");
        } else {
            $closeMatchHeader.addClass("d-none");
        }
    }

    function setupExpandGalleryCards() {
        var communityGalleryExpanded = false;
        var qmiGalleryExpanded = false;
        var $communityCards = _galleryLookupTable["community"].find("div.ProductSummary__community.card");
        var $qmiLargeCards = _galleryLookupTable["qmi"].find("section.QMIGalleryCard");
        var $qmiGridCards = _galleryLookupTable["qmi"].find("section.QMIGridCard");

        resetSetup();

        return {
            expandCommunities: expandCommunities,
            expandQMIs: expandQMIs,
            resetSetup: resetSetup
        };

        function expandCommunities() {
            communityGalleryExpanded = true;

            $communityCards.each(function(){
                $(this).removeClass("d-none");
            });

            setupCloseMatchBreak();

            // Update the text for Communities
            var $communityCountText = _galleryLookupTable["community"].find(".ProductSummaryV2__community-count-text");
            $communityCountText.find(".ProductSummaryV2__default-text").hide();
            $communityCountText.find(".ProductSummaryV2__show-all-text").show();
            _galleryLookupTable["community"].find(".community-expand").addClass("d-none");
        }

        function expandQMIs() {
            qmiGalleryExpanded = true;
            $qmiLargeCards.each(function(){
                $(this).removeClass("d-none").addClass("d-md-block");
            });
            $qmiGridCards.each(function(){
                $(this).removeClass("d-none");
            });

            setupCloseMatchBreak();

            // Update the text for QMIs
            var $qmiCountText = _galleryLookupTable["qmi"].find(".ProductSummaryV2__qmi-count-text");
            $qmiCountText.find(".ProductSummaryV2__default-text").hide();
            $qmiCountText.find(".ProductSummaryV2__show-all-text").show();
            _galleryLookupTable["qmi"].find(".qmi-expand").addClass("d-none");
        }

        function resetSetup() {
            $communityCards = _galleryLookupTable["community"].find("div.ProductSummary__community.card");
            $qmiLargeCards = _galleryLookupTable["qmi"].find("section.QMIGalleryCard");
            $qmiGridCards = _galleryLookupTable["qmi"].find("section.QMIGridCard");

            if (!communityGalleryExpanded) {
                $communityCards.each(function(index) {
                    var $this = $(this);
                    if (index > 5) $this.addClass("d-none");
                    else $this.removeClass("d-none");
                });
            }
            
            if (!qmiGalleryExpanded) {
                $qmiLargeCards.each(function(index){
                    var $this = $(this);
                    if (index > 5) {
                        $this.addClass("d-none").removeClass("d-md-block");
                    }
                    else $this.removeClass("d-none").addClass("d-md-block");
                });
                $qmiGridCards.each(function(index){
                    var $this = $(this);
                    if (index > 5) $this.addClass("d-none");
                    else $this.removeClass("d-none");
                });
            }
        }
        
    }
}

module.exports = component(ProductSummaryV2);
